import logo from './logo.svg'
import './App.css';

function App() {
  return (
    <>
    <div className="main-container">
      <div className="intro-container">
        <img src={logo} alt="logo"/>
        <h1 className="headline">Пространство открытого общения</h1>
        <div className="intro-message-container">
          <p className="intro-text">Формат сетевого взаимодействия при котором отсутствуют всякие иерархии, где все могут делится мыслями и идеями не делясь на заданные роли спикеров и аудитории</p>
          <a href="/" className="intro-participate">Стать участником</a>
        </div>
      </div>
    </div>
    <div className="description-container">
      <div className="main-container">
      <div className="description-content-area">
        <h2 className="description-title">Наша эксклюзивная площадка позволит:</h2>
        <div className="description-unit">
          <div>
            <h3 className="unit-headline">Обсуждать только то что вам интересно</h3>
            <p className="unit-tagline">Предлагайте темы, формируя круги взаимодействий</p>
          </div>
        </div>
        <div className="description-unit">
          <div>
            <h3 className="unit-headline">Объединятся с единомышленниками </h3>
            <p className="unit-tagline">Присоединяйтесь к интересующим вас дискуссиям</p>
          </div>
        </div>
        <div className="description-unit">
          <div>
            <h3 className="unit-headline">Открывать себя по новому и заводить новые знакомства</h3>
            <p className="unit-tagline">Стройте связи с теми, с кем возможно никогда бы не встретились по другому</p>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div className="main-container">
    <div className="participate-container">
      <a className="participate-link" href="/">Стать участником</a>
    </div>
    <div className="anounce-container">
      <h3 className="anounce-headline">Скоро будет более детальная информация</h3>
      <a href="/" className="anounce-invitation">Подписывайтесь на наш телеграмм канал чтобы узнать больше</a>
    </div>
    <footer>
      <p>© 2021 Antimeetup</p>
    </footer>
    </div>
    </>
  );
}

export default App;
